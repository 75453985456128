import React, { useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';

const ContactUsComponent = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: ''
    });
    const [fieldErrors, setFieldErrors] = useState({});
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [successMessage, setSuccessMessage] = useState(false);
    const [buttonText, setButtonText] = useState('Send Message');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateForm = () => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const mobileRegex = /^[6-9]\d{9}$/;

        if (!formData.name) {
            errors.name = "Full name is required";
        }
        if (!formData.email || !emailRegex.test(formData.email)) {
            errors.email = "Valid email is required";
        }
        if (!formData.mobile || !mobileRegex.test(formData.mobile)) {
            errors.mobile = "Valid Indian mobile number is required";
        }
        if (!formData.message) {
            errors.message = "Message is required";
        }

        return errors;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            return;
        }

        setButtonText('Sending...'); // Change button text when message is being sent
        setIsSubmitting(true);  // Disable the button after clicking

        const data = {
            ...formData,
            recaptchaToken: recaptchaToken,
        };

        // Send the email using Email.js
        emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_REACH_US_TEMPLATE_ID,
                data,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            )
            .then((response) => {
                setSuccessMessage(true);
                setFormData({
                    name: '',
                    email: '',
                    mobile: '',
                    message: ''
                });
                setButtonText('Send Message');  // Revert button text after success
                setIsSubmitting(false);  // Enable the button after success
                setTimeout(() => {
                    setSuccessMessage(false);
                }, 5000);
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
                setButtonText('Send Message');  // Revert button text if there's an error
                setIsSubmitting(false);  // Enable the button after error
            });
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    return (
        <div className="contact-main">
            <h1>Contact Us</h1>
            <p>Any question or remarks? Just write us a message!</p>
            <div className="contact-section">
                <div className="contact-info">
                    <h2>Contact Information</h2>
                    <p>Fill up the form and our team will get back to you within 24 hours.</p>
                    <ul>
                        <li>
                            <a href="tel:+918097987769"><i className='bx bx-mobile-alt'></i> +91 8097987769</a>
                        </li>
                        <li>
                            <a href="mailto:support@wecraftmemories.com"><i className='bx bx-envelope'></i> support@wecraftmemories.com</a>
                        </li>
                        <li>
                            <i className='bx bx-map'></i> Head Office - Mumbai
                        </li>
                    </ul>
                </div>
                <div className="contact-form">
                    <h2>Reach Us</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input 
                                type="text" 
                                name="name" 
                                placeholder="Full Name"
                                value={formData.name} 
                                onChange={handleInputChange} 
                            />
                            {fieldErrors.name && <p className="error-message">{fieldErrors.name}</p>}
                        </div>
                        <div className="form-group">
                            <input 
                                type="email" 
                                name="email" 
                                placeholder="Email Address"
                                value={formData.email} 
                                onChange={handleInputChange} 
                            />
                            {fieldErrors.email && <p className="error-message">{fieldErrors.email}</p>}
                        </div>
                        <div className="form-group">
                            <input 
                                type="tel" 
                                name="mobile" 
                                placeholder="Mobile Number"
                                value={formData.mobile} 
                                onChange={handleInputChange} 
                            />
                            {fieldErrors.mobile && <p className="error-message">{fieldErrors.mobile}</p>}
                        </div>
                        <div className="form-group">
                            <textarea 
                                name="message" 
                                rows="6" 
                                placeholder="Write your message..."
                                value={formData.message} 
                                onChange={handleInputChange}
                            ></textarea>
                            {fieldErrors.message && <p className="error-message">{fieldErrors.message}</p>}
                        </div>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_TOKEN}
                            onChange={handleRecaptchaChange}
                            size="invisible"
                        />
                        <button type="submit" className="submit-btn" disabled={isSubmitting}>
                                {buttonText}
                        </button>
                    </form>
                    {successMessage && (
                        <div className="popup-success">
                            <p>Message sent successfully!</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContactUsComponent;
import React from "react";

const AboutUsComponent = () => {
    return(
        <div className="wcm-container content-main">
            <div className="content-section">
                <h2 className="content-title">About Us</h2>
                <div className="content-detail">
                    <p>Welcome to WeCraftMemories, where magical moments begin and childhood memories are made. We're more than just a children's clothing rental service we're your partner in creating unforgettable moments for your little ones.</p>
                </div>
            </div>
            <div className="content-section">
                <h2 className="content-title">Our Story</h2>
                <div className="content-detail">
                    <p>At WeCraftMemories, our journey began with a simple idea: to make every precious moment in your child's life a little more special. We believe that dressing up is not just about fabric and style it's about fostering creativity, sparking imagination, and celebrating the joy of childhood.</p>
                    <p>Our founder, a parent just like you, embarked on this adventure when searching for the perfect outfit for a special family photoshoot. Frustrated by the high costs and the fact that children outgrow their clothes in the blink of an eye, we decided to create a solution. Thus, WeCraftMemories was born, providing an array of beautiful and themed clothing options for children aged 0-12 months, without the hassle of long-term ownership.</p>
                </div>
            </div>
            <div className="content-section">
                <h2 className="content-title">What Sets Us Apart</h2>
                <div className="content-detail">
                    <p><b>Unique Selection:</b> We offer a handpicked selection of the latest and most adorable clothing for your little ones, ensuring your kid stands out in every photo and event.</p>
                    <p><b>Quality Assurance:</b> Our garments are made with the utmost care and attention to detail, guaranteeing not only style but also comfort for your baby.</p>
                    <p><b>Affordability:</b> We understand that children's clothing can be expensive, especially for garments that are only worn a few times. With WeCraftMemories, you can access high-quality clothing at a fraction of the cost.</p>
                    <p><b>Convenience:</b> We've made renting baby clothes as easy as possible, with hassle-free online ordering, doorstep delivery, and simple returns.</p>
                </div>
            </div>
            <div className="content-section">
                <h2 className="content-title">Join Our Journey</h2>
                <div className="content-detail">
                    <p>We're thrilled to be a part of your child's journey from their very first smile to their first steps and beyond. Thank you for choosing WeCraftMemories to be a part of these precious moments.</p>
                    <p>Whether it's a milestone photoshoot, a family gathering, or just an ordinary day made extraordinary, let us help you dress your little one in style. Together, let's create beautiful memories that will last a lifetime.</p>
                    <p>If you have any questions or need assistance, please don't hesitate to reach out to our friendly team. We're here to make your experience with WeCraftMemories as delightful as possible.</p>
                </div>
            </div>
        </div>
    );
}

export default AboutUsComponent;
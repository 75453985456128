import React from "react";
import { Link } from "react-router-dom";

const ServiceComponent = () => {
    return(
        <div className="wcm-container content-main">
            <div className="header">
                <h1>Our Services</h1>
            </div>
            
            <main className="main">
                <section className="section" id="renting">
                    <i className="bx bxs-t-shirt" style={{color: '#e78186'}}></i>
                    <h2>Renting Clothes</h2>
                    <p>Choose from a wide variety of woolen clothes themed around superheroes and famous characters for your kids.</p>
                    <Link to={'/rent/products'} className="service-button">Rent Clothes</Link>
                    {/* <button className="button">Learn More</button> */}
                </section>
                {/* <section className="section" id="selling">
                    <i className="bx bx-store-alt" style={{color: '#e78186'}}></i>
                    <h2>Selling Clothes</h2>
                    <p>Purchase new and gently used clothes at affordable prices.</p>
                    <Link to={'/sale/products'} className="service-button">Buy Clothes</Link>
                    <button className="button">Learn More</button>
                </section> */}
                {/* <section className="section" id="photoshoot">
                    <i className="bx bxs-camera" style={{color: '#e78186'}}></i>
                    <h2>Photoshoot Packages</h2>
                    <p>Capture precious moments with our professional photography services.</p>
                    <button className="button">Learn More</button>
                </section> */}
                <section className="section" id="photo-editing">
                    <i className="bx bx-image-alt" style={{color: '#e78186'}}></i>
                    <h2>Photo Editing</h2>
                    <p>Enhance your photos with our expert editing services.</p>
                    <Link to={'/contact'} className="service-button">Book Photo Editing</Link>
                </section>
            </main>
        </div>
    );
}

export default ServiceComponent;
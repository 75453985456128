const products = [
    {
        "_id": "66e6e73317b7d0d67248d205",
        "productCode": 1001,
        "productName": "Dino Roar Set",
        "displayImage": "/uploads/rent/product_display_image/Dinosaur-img-1001.jpg",
        "description": "Let your little one stomp around in this cozy dinosaur-themed woolen outfit, perfect for those wild moments. Designed for both boys and girls, this knitted wool ensemble is ideal for casual wear. Soft, breathable, and playful—just like your little explorer!",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 249,
            "actualRentPrice": 500,
            "depositPrice": 500,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1001/Dinosaur-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1001/Dinosaur-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1001/Dinosaur-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2e007a482f0b188f50fd",
        "ageGroupId": "66c9bdb5720ce43748903307",
        "genderId": "66ca2d9ee3a6d6db04be3467",
        "showOnWeb": 1,
        "isTopProduct": 0,
        "sortNumber": 16,
        "inUse": 1
    },
    {
        "_id": "66e6de88a2bc48372ff14178",
        "productCode": 1002,
        "productName": "Minnie Magic Dress",
        "displayImage": "/uploads/rent/product_display_image/MinnieMouseBig-img-1002.jpg",
        "description": "Dress your little princess in this adorable Minnie Mouse outfit. Made from soft wool and perfect for casual playdates or photoshoots, this knitted dress will bring joy and magic to any occasion.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 249,
            "actualRentPrice": 500,
            "depositPrice": 1000,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1002/MinnieMouseBig-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1002/MinnieMouseBig-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1002/MinnieMouseBig-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1002/MinnieMouseBig-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2de75c9734967db3c990",
        "ageGroupId": "66ca2c9f4bb2578330270a96",
        "genderId": "66ca2d9a304f32992dcd1660",
        "showOnWeb": 1,
        "isTopProduct": 0,
        "sortNumber": 15,
        "inUse": 1
    },
    {
        "_id": "66e6e52cd37aad5cfaafc57c",
        "productCode": 1003,
        "productName": "Aladdin Adventure Costume",
        "displayImage": "/uploads/rent/product_display_image/Alladin-img-1003.jpg",
        "description": "Take your baby on a magic carpet ride with our Aladdin-inspired woolen outfit. Ideal for adventurous little ones, this knitted set is perfect for imaginative play or a casual day out. Crafted with love in India, it's soft and cozy for your baby’s comfort.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 249,
            "actualRentPrice": 600,
            "depositPrice": 1000,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1003/Alladin-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1003/Alladin-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1003/Alladin-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1003/Alladin-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2de75c9734967db3c990",
        "ageGroupId": "66ca2c9f4bb2578330270a96",
        "genderId": "66ca2d941d3c55163c6ec7bb",
        "showOnWeb": 1,
        "isTopProduct": 0,
        "sortNumber": 14,
        "inUse": 1
    },
    {
        "_id": "66e6e4b1b4ef888e6c3eda34",
        "productCode": 1004,
        "productName": "Tinkerbell Fairy Wings",
        "displayImage": "/uploads/rent/product_display_image/Tinkerbell-img-1004.jpg",
        "description": "Let your little fairy sprinkle some magic dust in this beautiful Tinkerbell-inspired outfit. Crafted from soft wool and knitted for comfort, this dress is ideal for casual wear or themed photoshoots.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 299,
            "actualRentPrice": 600,
            "depositPrice": 500,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1004/Tinkerbell-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1004/Tinkerbell-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1004/Tinkerbell-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1004/Tinkerbell-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2de75c9734967db3c990",
        "ageGroupId": "66c9bdb5720ce43748903307",
        "genderId": "66ca2d9a304f32992dcd1660",
        "showOnWeb": 1,
        "isTopProduct": 1,
        "sortNumber": 13,
        "inUse": 1
    },
    {
        "_id": "66e6d56f4da025a47d09226f",
        "productCode": 1005,
        "productName": "Minnie Mouse Pink Delight",
        "displayImage": "/uploads/rent/product_display_image/MinnieMousePink-img-1005.jpg",
        "description": "A delightful woolen Minnie Mouse pink dress that’s soft, warm, and perfect for a cozy day. Ideal for casual wear, your little one will look absolutely adorable in this charming pink outfit.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 249,
            "actualRentPrice": 500,
            "depositPrice": 650,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1005/MinnieMousePink-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1005/MinnieMousePink-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1005/MinnieMousePink-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1005/MinnieMousePink-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2de75c9734967db3c990",
        "ageGroupId": "66ca2c900203c22d7b5534dd",
        "genderId": "66ca2d9a304f32992dcd1660",
        "showOnWeb": 1,
        "isTopProduct": 1,
        "sortNumber": 12,
        "inUse": 1
    },
    {
        "_id": "66e6e3f3fe8731412e32163a",
        "productCode": 1006,
        "productName": "Mario Power-Up Costume",
        "displayImage": "/uploads/rent/product_display_image/mario-img-1006.jpg",
        "description": "Let your little one power up in style with this Mario-inspired woolen outfit. Made with care in India, this playful costume is knitted from soft wool, perfect for casual days and fun photoshoots.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 299,
            "actualRentPrice": 600,
            "depositPrice": 1000,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1006/mario-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1006/mario-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1006/mario-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1006/mario-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1006/mario-img6.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2e120fb0ce09e7bf76c0",
        "ageGroupId": "66ca2c9f4bb2578330270a96",
        "genderId": "66ca2d941d3c55163c6ec7bb",
        "showOnWeb": 1,
        "isTopProduct": 1,
        "sortNumber": 11,
        "inUse": 1
    },
    {
        "_id": "66e6de45fa6ff425498b0ba3",
        "productCode": 1007,
        "productName": "Goku Kid Saiyan Set",
        "displayImage": "/uploads/rent/product_display_image/Goku-img-1007.jpg",
        "description": "Channel the power of Goku with this anime-inspired woolen outfit. Soft and knitted to perfection, this set is perfect for a casual day or a fun-themed photoshoot. Get ready for an adventure with your little Saiyan!",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 249,
            "actualRentPrice": 500,
            "depositPrice": 1000,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1007/Goku-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1007/Goku-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1007/Goku-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1007/Goku-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2dea35366c9da215b63c",
        "ageGroupId": "66ca2c9f4bb2578330270a96",
        "genderId": "66ca2d941d3c55163c6ec7bb",
        "showOnWeb": 1,
        "isTopProduct": 1,
        "sortNumber": 10,
        "inUse": 1
    },
    {
        "_id": "66e6e310cc5d81e50d5867f7",
        "productCode": 1008,
        "productName": "Batman Baby Costume",
        "displayImage": "/uploads/rent/product_display_image/Batman-img-1008.jpg",
        "description": "Let your little superhero take flight in this adorable Batman-inspired woolen outfit. Made with soft, breathable wool, this knitted costume is perfect for casual wear and those special photoshoots.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 299,
            "actualRentPrice": 600,
            "depositPrice": 800,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1008/Batman-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1008/Batman-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1008/Batman-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1008/Batman-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1008/Batman-img6.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1008/Batman-img7.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2de227c5e6cc833a383e",
        "ageGroupId": "66ca2c98b721b6bc7bf75375",
        "genderId": "66ca2d941d3c55163c6ec7bb",
        "showOnWeb": 1,
        "isTopProduct": 1,
        "sortNumber": 9,
        "inUse": 1
    },
    {
        "_id": "66e6e82a51b2d12550a7f3d7",
        "productCode": 1009,
        "productName": "Little Chef Outfit",
        "displayImage": "/uploads/rent/product_display_image/Chef-img-1009.jpg",
        "description": "Get your little one ready to cook up some fun in this woolen Chef outfit! Perfect for casual wear or culinary-themed photoshoots, this outfit is as cute as it is cozy.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 299,
            "actualRentPrice": 600,
            "depositPrice": 800,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1009/Chef-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1009/Chef-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1009/Chef-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1009/Chef-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1009/Chef-img6.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2def7db050add39c152f",
        "ageGroupId": "66ca2c98b721b6bc7bf75375",
        "genderId": "66ca2d941d3c55163c6ec7bb",
        "showOnWeb": 1,
        "isTopProduct": 0,
        "sortNumber": 8,
        "inUse": 1
    },
    {
        "_id": "66e6e7a6eda783819fe435d0",
        "productCode": 1010,
        "productName": "Rocky the Star Costume",
        "displayImage": "/uploads/rent/product_display_image/Rocky-img-1010.jpg",
        "description": "Inspired by the movie, this woolen Rocky outfit will have your baby looking like a little champion. Soft and comfortable, it’s perfect for movie-themed photoshoots or casual days.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 249,
            "actualRentPrice": 500,
            "depositPrice": 800,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1010/Rocky-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1010/Rocky-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1010/Rocky-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1010/Rocky-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2e0d8f66c19d5ae90a33",
        "ageGroupId": "66ca2c98b721b6bc7bf75375",
        "genderId": "66ca2d941d3c55163c6ec7bb",
        "showOnWeb": 1,
        "isTopProduct": 0,
        "sortNumber": 7,
        "inUse": 1
    },
    {
        "_id": "66e6e658677394640aafd104",
        "productCode": 1011,
        "productName": "Hello Kitty Cuteness Set",
        "displayImage": "/uploads/rent/product_display_image/Hello-Kitty-img-1011.jpg",
        "description": "Dress your little one in this adorable Hello Kitty-themed woolen outfit. Soft, cuddly, and knitted for comfort, it’s perfect for casual wear or a fun photoshoot.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 299,
            "actualRentPrice": 600,
            "depositPrice": 800,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1011/Hello-Kitty-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1011/Hello-Kitty-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1011/Hello-Kitty-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1011/Hello-Kitty-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2de75c9734967db3c990",
        "ageGroupId": "66ca2c98b721b6bc7bf75375",
        "genderId": "66ca2d9a304f32992dcd1660",
        "showOnWeb": 1,
        "isTopProduct": 1,
        "sortNumber": 6,
        "inUse": 1
    },
    {
        "_id": "66e6e6bb2600f787798013ab",
        "productCode": 1012,
        "productName": "Snow White Princess Dress",
        "displayImage": "/uploads/rent/product_display_image/SnowWhite-img-1012.jpg",
        "description": "Let your little princess shine in this Snow White-inspired woolen dress. Soft and knitted for comfort, it’s perfect for a fairy tale-themed day or a special photoshoot.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 299,
            "actualRentPrice": 600,
            "depositPrice": 800,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1012/SnowWhite-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1012/SnowWhite-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1012/SnowWhite-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1012/SnowWhite-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2de75c9734967db3c990",
        "ageGroupId": "66ca2c98b721b6bc7bf75375",
        "genderId": "66ca2d9a304f32992dcd1660",
        "showOnWeb": 1,
        "isTopProduct": 1,
        "sortNumber": 5,
        "inUse": 1
    },
    {
        "_id": "66e6de2cd2f3f64c91d4fc23",
        "productCode": 1013,
        "productName": "Ice Cream Cocoon",
        "displayImage": "/uploads/rent/product_display_image/icecreamcone-img-1013.jpg",
        "description": "Sweet as can be, this woolen ice cream cocoon is perfect for your little one. Soft and cozy, it’s ideal for casual wear or a fun photoshoot.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 249,
            "actualRentPrice": 500,
            "depositPrice": 500,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1013/icecreamcone-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1013/icecreamcone-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1013/icecreamcone-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2e09c9c809651738a2f4",
        "ageGroupId": "66c9bdb5720ce43748903307",
        "genderId": "66ca2d9ee3a6d6db04be3467",
        "showOnWeb": 1,
        "isTopProduct": 1,
        "sortNumber": 4,
        "inUse": 1
    },
    {
        "_id": "66e6e2d03675e1be0c749a6e",
        "productCode": 1014,
        "productName": "Blooming Flower Cap",
        "displayImage": "/uploads/rent/product_display_image/Flower-img-1014.jpg",
        "description": "This adorable woolen flower cap is perfect for a casual day out or a fun garden-themed photoshoot. Knitted with love, it’s as soft as it is stylish.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 199,
            "actualRentPrice": 400,
            "depositPrice": 500,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1014/Flower-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2e0468050826edef8d14",
        "ageGroupId": "66c9bdb5720ce43748903307",
        "genderId": "66ca2d9a304f32992dcd1660",
        "showOnWeb": 1,
        "isTopProduct": 1,
        "sortNumber": 3,
        "inUse": 1
    },
    {
        "_id": "66e6e99aebbdd57e0d65ac8e",
        "productCode": 1015,
        "productName": "Cinderella Dream Dress",
        "displayImage": "/uploads/rent/product_display_image/Cindrella-img-1015.jpg",
        "description": "Dress your little one in this dreamy Cinderella woolen outfit, perfect for casual wear or a magical photoshoot. Soft and knitted, this dress will make your baby feel like royalty.",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 299,
            "actualRentPrice": 600,
            "depositPrice": 800,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1015/Cindrella-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1015/Cindrella-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1015/Cindrella-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1015/Cindrella-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2de75c9734967db3c990",
        "ageGroupId": "66ca2c98b721b6bc7bf75375",
        "genderId": "66ca2d9a304f32992dcd1660",
        "showOnWeb": 1,
        "isTopProduct": 0,
        "sortNumber": 2,
        "inUse": 1
    },
    {
        "_id": "66e6ea0d62c423916d85857e",
        "productCode": 1016,
        "productName": "Minnie Mouse Charm",
        "displayImage": "/uploads/rent/product_display_image/minnie-Mouse-img-1016.jpg",
        "description": "An adorable woolen Minnie Mouse outfit designed for comfort and style. Perfect for casual days or a themed photoshoot, this outfit is as sweet as your little one!",
        "colors": [
            {
                "colorCode": "#af0c13",
                "inUse": 1
            },
            {
                "colorCode": "#e6cc46",
                "inUse": 1
            }
        ],
        "prices":  {
            "discountedRentPrice": 249,
            "actualRentPrice": 500,
            "depositPrice": 500,
            "inUse": 1
        },
        "images": [
            {
                "image": "/uploads/rent/products/1016/minnie-Mouse-img2.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1016/minnie-Mouse-img3.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1016/minnie-Mouse-img4.jpg",
                "imageTitle": "",
                "inUse": 1
            },
            {
                "image": "/uploads/rent/products/1016/minnie-Mouse-img5.jpg",
                "imageTitle": "",
                "inUse": 1
            }
        ],
        "attributes": [
            {
                "attributeTitle": "Origin",
                "attributeValue": "Made in India"
            },
            {
                "attributeTitle": "Weave Type",
                "attributeValue": "Knitted"
            },
            {
                "attributeTitle": "Fabric",
                "attributeValue": "Wool"
            },
            {
                "attributeTitle": "Occasion",
                "attributeValue": "Casual"
            }
        ],
        "categoryId": "66ca2de75c9734967db3c990",
        "ageGroupId": "66c9bdb5720ce43748903307",
        "genderId": "66ca2d9a304f32992dcd1660",
        "showOnWeb": 1,
        "isTopProduct": 1,
        "sortNumber": 1,
        "inUse": 1
    }

    //Sample
    // {
    //     "_id": "66ca3d92059ae3584ea386fa",
    //     "productCode": 1001,
    //     "productName": "",
    //     "displayImage": "/uploads/rent/product_display_image/.jpg",
    //     "description": "",
    //     "colors": [
    //         {
    //             "colorCode": "#af0c13",
    //             "inUse": 1
    //         },
    //         {
    //             "colorCode": "#e6cc46",
    //             "inUse": 1
    //         }
    //     ],
    //     "prices":  {
    //         "discountedRentPrice": 0,
    //         "actualRentPrice": 0,
    //         "depositPrice": 0,
    //         "inUse": 1
    //     },
    //     "images": [
    //         {
    //             "image": "/uploads/rent/products/1001/.jpg",
    //             "imageTitle": "",
    //             "inUse": 1
    //         },
    //         {
    //             "image": "/uploads/rent/products/1001/.jpg",
    //             "imageTitle": "",
    //             "inUse": 1
    //         },
    //         {
    //             "image": "/uploads/rent/products/1001/.jpg",
    //             "imageTitle": "",
    //             "inUse": 1
    //         }
    //     ],
    //     "attributes": [
    //         {
    //             "attributeTitle": "Origin",
    //             "attributeValue": "Made in India"
    //         },
    //         {
    //             "attributeTitle": "Designed by",
    //             "attributeValue": "We Craft Memories"
    //         },
    //         {
    //             "attributeTitle": "Size",
    //             "attributeValue": "Height: 14 in, Width: 10 in"
    //         },
    //         {
    //             "attributeTitle": "Weave Type",
    //             "attributeValue": "Knitted"
    //         },
    //         {
    //             "attributeTitle": "Fabric",
    //             "attributeValue": "Wool"
    //         },
    //         {
    //             "attributeTitle": "Occasion",
    //             "attributeValue": "Casual"
    //         }
    //     ],
    //     "categoryId": "",
    //     "ageGroupId": "",
    //     "genderId": "",
    //     "showOnWeb": 1,
    //     "isTopProduct": 1,
    //     "sortNumber": 1,
    //     "inUse": 1
    // }
];

module.exports = products;
import React from "react";

const ShipmentAndReturnComponent = () => {
    return(
        <div className="wcm-container content-main">
            <div className="content-section">
                <h1 className="content-title">Shipment and Return Policy</h1>
            </div>
            <div className="content-section">
                <h2 className="content-title">1. Shipping and Delivery</h2>
                <ul className="content-detail">
                    <li><b>Shipping Locations: </b>We currently offer shipping within [Mumbai]. Please note that shipping may not be available to all areas within these locations.</li>
                    <li><b>Delivery Time: </b>The estimated delivery time for your rented baby clothes will be provided at the time of rental reservation. Delivery times may vary depending on your location and other factors. Please allow for additional time during peak seasons.</li>
                    <li><b>Delivery Address: </b>You are responsible for providing an accurate and complete delivery address. We will not be liable for any delays or delivery issues resulting from incorrect or incomplete address information.</li>
                    <li><b>Delivery Confirmation: </b>Once your rented items are shipped, we will provide you with tracking information and a confirmation email to the email address associated with your account.</li>
                </ul>
            </div>
            <div className="content-section">
                <h2 className="content-title">2. Rental Returns</h2>
                <ul className="content-detail">
                    <li><b>Return Instructions: </b>Please follow the return instructions provided with your rented items to ensure a smooth return process. Failure to adhere to these instructions may result in delays or additional charges.</li>
                    <li><b>Return Condition: </b>You are responsible for returning the rented baby clothes in the same condition as they were received, subject to normal wear and tear. Any damage beyond normal wear and tear may result in additional charges, as outlined in the rental agreement.</li>
                </ul>
            </div>
            <div className="content-section">
                <h2 className="content-title">3. Return Deadline</h2>
                <ul className="content-detail">
                    <li><b>Return Date: </b>The return date for your rented baby clothes will be specified in the rental agreement. It is your responsibility to ensure that the items are returned by the agreed-upon return date.</li>
                    <li><b>Late Returns: </b>If you are unable to return the rented items by the specified return date, please contact us as soon as possible to discuss the situation and avoid late fees.</li>
                </ul>
            </div>
            <div className="content-section">
                <h2 className="content-title">4. Return Shipping</h2>
                <ul className="content-detail">
                    <li><b>Return Shipping: </b>You are responsible for covering the cost of return shipping unless otherwise specified in the rental agreement.</li>
                    <li><b>Return Packaging: </b>Please use the original packaging or a suitable alternative to return the rented items safely.</li>
                </ul>
            </div>
            <div className="content-section">
                <h2 className="content-title">5. Refunds and Exchanges</h2>
                <ul className="content-detail">
                    <li><b>Refunds: </b>Refunds for returned items will be processed in accordance with our Refund Policy. Please refer to our Refund Policy for detailed information on eligibility and the refund process.</li>
                    <li><b>Exchanges: </b>We may offer exchanges for certain items, subject to availability. Please contact us for more information on exchanges.</li>
                </ul>
            </div>
            <div className="content-section">
                <h2 className="content-title">6. Contact Us</h2>
                <ul className="content-detail">
                    <li>If you have any questions or concerns regarding our shipment and return policy, please contact us at <a href="mailto:support@wecraftmemories.com" rel="noreferrer">support@wecraftmemories.com</a>.</li>
                </ul>
            </div>
        </div>
    );
}

export default ShipmentAndReturnComponent;